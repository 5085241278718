export async function decodeAccessData(instance, data) {
	//allocate memory to decode TlvData packages
	const tlvDataPtr = instance._malloc(data.length)
	const tlvTransportSize = instance._tlv_data_get_size_TlvAccessTransport()
	const tlvDataSize = instance._tlv_data_get_size_TlvAccessData()
	let errPtr = instance._malloc(4)
	const kindPtr = instance._malloc(4)
	const TlvStructPtr = instance._malloc(tlvTransportSize + 1)
	const outDataPtr = instance._malloc(1200)
	// console.log(outDataPtr)
	// Copy data to heap at address of tlvDataPtr so it is accessable from webassambely
	instance.HEAPU8.set(data, tlvDataPtr)
	let ret = instance._tlv_transport_decoder(
		tlvDataPtr,
		data.length,
		errPtr,
		TlvStructPtr,
		tlvTransportSize + 1,
		kindPtr
	)

	let answer
	const transportKind = instance.getValue(kindPtr, "i32")
	if (transportKind === 13) {
		//statusError_PRESENT
		const statusErr = instance._tlv_get_transport_status_error(TlvStructPtr)
		answer = { status: statusErr }
	} else {
		const dataSize = instance._tlv_get_transport_payload(
			TlvStructPtr,
			outDataPtr,
			1200
		)

		if (ret === 0) {
			ret = instance._tlv_data_decoder(
				outDataPtr,
				data.length,
				errPtr,
				TlvStructPtr,
				tlvDataSize + 1,
				kindPtr
			)

			if (ret === 0) {
				const kind = instance.getValue(kindPtr, "i32")

				switch (kind) {
					case 0: //none
						// todo throw error
						break

					case 2: //ansVersion_PRESENT
						answer = decodeAccessDataVersionAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 4: //ansLs_PRESENT
						answer = decodeAccessDataLsAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 6: //ansCd_PRESENT
						answer = decodeAccessDataCdAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 8: //ansGet_PRESENT
						answer = decodeAccessDataGetAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 10: //ansPut_PRESENT
						answer = decodeAccessDataPutAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 11: //fileSliceSend_PRESENT
						answer = decodeAccessDataFileSlice(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 12: //ansFileSliceSend_PRESENT
						answer = decodeAccessDataFileSliceAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 14: // ansRen_PRESENT
						answer = decodeAccessDataRenameAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					case 16: //ansDel_PRESENT
						answer = decodeAccessDataDeleteAnswer(
							instance,
							TlvStructPtr,
							tlvDataSize
						)
						break

					default:
						break
				}
			}
		}
	}
	// free allocated Memory for Webassembly
	instance._free(tlvDataPtr)
	instance._free(TlvStructPtr)
	instance._free(errPtr)
	instance._free(kindPtr)

	return answer
}

function decodeAccessDataLsAnswer(instance, TlvStructPtr, tlvDataSize) {
	//TODO: Where to put offset for multiple calls
	// get status, if status not ok, no need to parse rest of the answer
	let ret
	let lsEntriesArr
	let stat
	let numOfElements
	stat = instance._tlv_get_data_get_lsans_get_stat(TlvStructPtr)
	if (stat >= 0) {
		if (stat === 0) {
			//get number of list elements
			const numPtr = instance._malloc(4)
			ret = instance._tlv_get_data_lsans_get_num_of_entries(
				TlvStructPtr,
				numPtr
			)
			numOfElements = instance.getValue(numPtr, "i32")
			instance._free(numPtr)
			lsEntriesArr = new Array(numOfElements)
			// get strings
			let strPtr = instance._malloc(4)
			let strP
			let lsStringArr
			let lsString
			const decoder = new TextDecoder()
			//iterate through list
			for (let i = 0; i < numOfElements; i++) {
				// store Address in pointer space of Data where the string is stored
				instance._tlv_get_data_lsans_get_p_string(TlvStructPtr, i, strPtr)
				//get Address from pointerspace
				strP = instance.getValue(strPtr, "i32")
				// get data from Address where the string is stored
				lsStringArr = new Uint8Array(instance.HEAPU8.buffer, strP, 31)

				lsEntriesArr[i] = decoder.decode(lsStringArr)
			}
		}
		const answer = {
			kind: 4,
			status: stat,
			num: numOfElements, //TODO: number of elements to be edited or call sendLsCmd multiple times
			list: lsEntriesArr
		}
		return answer
	}
	// throw error
}

function decodeAccessDataVersionAnswer(instance, TlvStructPtr, tlvDataSize) {
	const vers = instance._tlv_get_data_version_answ(TlvStructPtr)
	if (vers >= 0) {
		const answer = { version: vers }
		return answer
	} else {
		//todo throw error
	}
}

function decodeAccessDataCdAnswer(instance, TlvStructPtr, tlvDataSize) {
	const stat = instance._tlv_get_data_cd_answ_status(TlvStructPtr)
	if (stat >= 0) {
		const answer = { status: stat }
		return answer
	} else {
		//todo throw error
	}
}

function decodeAccessDataGetAnswer(instance, TlvStructPtr, tlvDataSize) {
	const stat = instance._tlv_get_data_get_answer_status(TlvStructPtr)
	if (stat >= 0) {
		const sha256Ptr = instance._malloc(32)

		const size = instance._tlv_get_data_get_answer_size(TlvStructPtr)
		let sha256 = new Uint8Array(32)
		instance._tlv_get_data_get_answer_sha256(TlvStructPtr, sha256Ptr)
		sha256.set(new Uint8Array(instance.HEAPU8.buffer, sha256Ptr, 32))
		instance._free(sha256Ptr)

		const answer = { status: stat, fileSize: size, sha256: sha256 }

		return answer
	} else {
		//todo throw error
	}
}

function decodeAccessDataPutAnswer(instance, TlvStructPtr, tlvDataSize) {
	const stat = instance._tlv_get_data_put_answer_status(TlvStructPtr)
	if (stat >= 0) {
		const answer = { status: stat }
		return answer
	} else {
		//todo throw error
	}
}

function decodeAccessDataFileSlice(instance, TlvStructPtr, tlvDataSize) {
	let size
	let offset
	size = instance._tlv_get_data_data_fileslice_size(TlvStructPtr)

	if (size >= 0) {
		offset = instance._tlv_get_data_fileslice_answer_offset(TlvStructPtr)
		if (offset >= 0) {
			const outPtr = instance._malloc(size)
			const ret = instance._tlv_get_data_fileslice_answer_data(
				TlvStructPtr,
				outPtr,
				size
			)
			if (ret === 0) {
				const outData = new Uint8Array(size)
				outData.set(new Uint8Array(instance.HEAPU8.buffer, outPtr, size))
				const answer = {
					fileSliceSize: size,
					fileOffset: offset,
					data: outData
				}
				instance._free(outPtr)
				return answer
			}
		}
	}
}
//todo throw error

function decodeAccessDataFileSliceAnswer(instance, TlvStructPtr, tlvDataSize) {
	const stat = instance._tlv_get_data_fileslice_answer_status(TlvStructPtr)
	if (stat >= 0) {
		const answer = { status: stat }
		return answer
	} else {
		//todo throw error
	}
}

function decodeAccessDataRenameAnswer(instance, TlvStructPtr, tlvDataSize) {
	const stat = instance._tlv_get_data_rename_answer_status(TlvStructPtr)

	if (stat >= 0) {
		const answer = { status: stat }
		return answer
	} else {
		//todo throw error
	}
}

function decodeAccessDataDeleteAnswer(instance, TlvStructPtr, tlvDataSize) {
	const stat = instance._tlv_get_data_delete_answer_status(TlvStructPtr)
	if (stat >= 0) {
		const answer = { status: stat }
		return answer
	} else {
		//todo throw error
	}
}

export async function decodeAccessTransportSystemCtrlAnswer(instance, data) {
	if (data === undefined || data.length === 0) {
		return
	}

	const tlvDataPtr = instance._malloc(data.length)
	const tlvTransportSize = instance._tlv_data_get_size_TlvAccessTransport()
	const tlvDataSize = instance._tlv_data_get_size_TlvAccessData()
	let errPtr = instance._malloc(4)
	const kindPtr = instance._malloc(4)
	const TlvStructPtr = instance._malloc(tlvTransportSize + 1)
	const outDataPtr = instance._malloc(1200)
	// Copy data to heap at address of tlvDataPtr so it is accessable from webassambely
	instance.HEAPU8.set(data, tlvDataPtr)
	let ret = instance._tlv_transport_decoder(
		tlvDataPtr,
		data.length,
		errPtr,
		TlvStructPtr,
		tlvTransportSize + 1,
		kindPtr
	)
	let answer
	if (ret === 0) {
		let stat
		const kind = instance._tlv_get_transport_sysctrl_type(TlvStructPtr)
		if (kind < 0) {
			// throw new Error("wrong not systeminfo answer", {})
		}
		switch (kind) {
			//deviceInfoGet_PRESENT
			case 1:
				answer = decodeAccessTransportSystemCtrlDeviceInfoAnswer(
					instance,
					TlvStructPtr
				)
				break

			//deviceTimeSet_PRESENT
			case 2:
				stat = instance._tlv_get_transport_device_time_answer(TlvStructPtr)
				if (stat >= 0) {
					answer = { status: stat }
				} else {
				}
				break
			//deviceReset_PRESENT
			case 3:
				stat = instance._tlv_get_transport_device_reset_answer(TlvStructPtr)
				if (stat >= 0) {
					answer = { status: stat }
				} else {
				}
				break
		}
	}
	instance._free(TlvStructPtr)
	return answer
}

function decodeAccessTransportSystemCtrlDeviceInfoAnswer(
	instance,
	TlvStructPtr
) {
	const fwNumberPtr = instance._malloc(4)
	const fwVersionMajorPtr = instance._malloc(4)
	const fwVersionMinorPtr = instance._malloc(4)
	const fwVersionBetaPtr = instance._malloc(4)
	const hwAssemblyVariantPtr = instance._malloc(2)
	const hwAssemblyVersionPtr = instance._malloc(4)
	const hwPrintNrPtr = instance._malloc(4)
	const hwPrintVersionPtr = instance._malloc(4)
	const serialNumberPtr = instance._malloc(4)
	let answer
	const ret = instance._tlv_get_transport_device_info_answer(
		TlvStructPtr,
		fwNumberPtr,
		fwVersionMajorPtr,
		fwVersionMinorPtr,
		fwVersionBetaPtr,
		hwAssemblyVariantPtr,
		hwAssemblyVersionPtr,
		hwPrintNrPtr,
		hwPrintVersionPtr,
		serialNumberPtr
	)
	if (ret === 0) {
		const fwNumber = instance.getValue(fwNumberPtr, "i32")
		const fwVersionMajor = instance.getValue(fwVersionMajorPtr, "i32")
		const fwVersionMinor = instance.getValue(fwVersionMinorPtr, "i32")
		const fwVersionBeta = instance.getValue(fwVersionBetaPtr, "i32")
		const hwAssemblyVariant = new Uint8Array(1)
		hwAssemblyVariant.set(
			new Uint8Array(instance.HEAPU8.buffer, hwAssemblyVariantPtr, 1)
		)
		const decoder = new TextDecoder()
		const hwAssemblyVariantStr = decoder.decode(hwAssemblyVariant)
		const hwAssemblyVersion = instance.getValue(hwAssemblyVersionPtr, "i32")
		const hwPrintNr = instance.getValue(hwPrintNrPtr, "i32")
		const hwPrintVersion = instance.getValue(hwPrintVersionPtr, "i32")
		const serialNum = instance.getValue(serialNumberPtr, "i32")
		answer = {
			firmwareNumber: fwNumber,
			firmwareVersionMajor: fwVersionMajor,
			firmwareVersionMinor: fwVersionMinor,
			firmwareVersionBeta: fwVersionBeta,
			hardwareAssemblyVariant: hwAssemblyVariantStr,
			hardwareAssemblyVersion: hwAssemblyVersion,
			hardwarePrintNr: hwPrintNr,
			hardwarePrintVersion: hwPrintVersion,
			serialNumber: serialNum
		}
	} else {
	}

	instance._free(fwNumberPtr)
	instance._free(fwVersionMajorPtr)
	instance._free(fwVersionMinorPtr)
	instance._free(fwVersionBetaPtr)
	instance._free(hwAssemblyVariantPtr)
	instance._free(hwAssemblyVersionPtr)
	instance._free(hwPrintNrPtr)
	instance._free(hwPrintVersionPtr)
	instance._free(serialNumberPtr)
	return answer
}
