import React, { useState, useEffect } from "react"
import { DocFolderContext } from "./ApiContext"

function DocFolderStore({ children }) {
	const [docFolderData, setDocFolderData] = useState(null)

	const url =
		process.env.NODE_ENV === "production"
			? "/docFolder"
			: "http://localhost:3000/docFolder"

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(url)
				if (!response.ok) {
					throw new Error("Network response was not ok.")
				}
				const data = await response.json()

				setDocFolderData(data)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchData()
	}, [url])

	return (
		<DocFolderContext.Provider value={[docFolderData, setDocFolderData]}>
			{children}
		</DocFolderContext.Provider>
	)
}
export default DocFolderStore
