import React from "react"

import { Box } from "@chakra-ui/react"

const DecoratorTriangleButtons = (props) => {
	return (
		<Box
			height={"25px"}
			width={"28px"}
			marginInlineStart={0}
			ml={0}
			bg={props.backgroundColor}
			clipPath="polygon(107% 0, 0 0, 0 107%)"
			border="1px solid"
			borderColor={props.borderColor}
		/>
	)
}

export default DecoratorTriangleButtons
