import React, { useContext, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import WebSerialButtonsErrorFallback from "../ErrorFallbacks/WebSerialButtonsErrorFallback"

import myModule from "../../utils/FileTransfer/TlvModules/tlvAccessModuleFile.mjs"
import { sendSystemDeviceInfoCmd } from "../../utils/FileTransfer/TlvAccessProtocol/TlvAccessCmd.js"
import { PortContext } from "../../contexts/WebSerialInstanceContext.js"
import { useAtom } from "jotai"
import deviceInfoAtom from "../../api/jotaiAtoms/deviceInfoAtom.js"
import { useConnectionStore } from "./../../api/zustandStores/WebSerialStores/isConnectedStore.js"

import { Box, Center } from "@chakra-ui/react"
import DashboardButtons from "../Dashboard/Buttons/DashboardButtons"
import LogoWhite from "../GUI/images/logo/logoWhiteIconSVG.svg"

// import DeviceCardHorizontal from "../../../../_TEMPLATES/Dashboard/DeviceCardHorizontal.jsx" //^ UNUSED device card grid for users devices
import TabNav from "../Navigation/TabNav/TabNav"
import { disconnectDevice } from "../hooks/deviceActions.js"
import { toast } from "react-toastify"
import { useTranslate } from "react-polyglot"
import isBlockingInteractionAtom from "../../api/jotaiAtoms/isBlockingInteraction.js"

import Overlay from "../GUI/Overlay.jsx"

const Dashboard = () => {
	const t = useTranslate()

	//^ ENABLE Mock data to see DEVICES FROM DeviceCardHorizontal
	// let mockUserDevices = [] //^ sets mockUserDevices.length to 0 for conditional rendering of WelcomeToMineralis

	//# set contextState for port and deviceInfo
	let [port, setPort] = useContext(PortContext)
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)
	const { isConnected, setIsConnected } = useConnectionStore()
	const [isBlockingInteraction, setIsBlockingInteraction] = useAtom(
		isBlockingInteractionAtom
	)

	const openSerialPort = async (instance) => {
		let port
		try {
			port = await navigator.serial.requestPort({
				filters: [{ usbVendorId: 1115 }]
			})
			port
				.open({
					baudRate: 115200,
					dataBits: 8,
					parity: "none",
					bufferSize: 2000,
					flowControl: "hardware"
				})
				.then(() => {
					setPort(port)
					setIsConnected(true)
				})
				.then(async (instance) => {
					//^ GET SYSTEM DEVICE INFO
					myModule().then(async (instance) => {
						const deviceInfoAnswer = await sendSystemDeviceInfoCmd(
							instance,
							port
						)
						setDeviceInfo(deviceInfoAnswer)
					})
				})
				.then(() => {
					toast.info(
						t("Toasts.toastReaderConnected", {
							_: "Gerät verbunden."
						}),
						{
							limit: 1
						}
					)
					toast.clearWaitingQueue()
				})
				.then(() => {
					myModule().then((instance) => {})
				})
		} catch (error) {
			console.log(error)
		} finally {
			//^ Close port if error occurs - not needed for now
		}
	}

	return (
		<Box>
			{isBlockingInteraction && <Overlay />}
			{/* //# TabNavigation and Connection Status */}
			<Box
				position={"fixed"}
				mr={{ base: "1rem", md: "5.5rem" }}
				paddingLeft={{ base: "2rem", md: "1.7rem", lg: "3.5rem" }}
				marginLeft={{ base: "1rem", md: "2rem", lg: "2rem" }}
				marginTop={"5.6rem"}
				marginBottom={"0"}
				pt={"75px"} //^ FOR MINERALIS HEADER
			>
				<TabNav
					mt={"550px"}
					defaultIndex={0}
					isConnected={isConnected}
					setIsConnected={setIsConnected}
					disconnectDevice={disconnectDevice}
					port={port}
					openSerialPort={openSerialPort}
				/>

				<ErrorBoundary FallbackComponent={WebSerialButtonsErrorFallback}>
					<Suspense fallback={<div>Loading</div>}>
						<DashboardButtons defaultIndex={0} />
					</Suspense>
				</ErrorBoundary>
			</Box>
			{/* //TODO: UNUSED CODE - DEVICE CARD GRID FOR USERS DEVICES */}
			{/* {mockUserDevices.length > 0 ? (
				<Box
					marginTop={"2rem"}
					marginX={16}
					maxH={"100vh"}
				>
					<SimpleGrid
						spacing={4}
						columnGap="12"
						rowGap={"0"}
						templateColumns="repeat(auto-fill, minmax(400px, 800px))"
						maxW={{ base: "100%", md: "100%", lg: "95%" }}
					>
						{mockUserDevices.map((device, index) => (
							<>
								{/* <DeviceCard
										key={device.serialNumber}
										device={device.deviceName}
										serialNumber={device.serialNumber}
										firmware={device.firmware}
										language={device.language}
										lastUpdate={device.lastUpdate}
										updateAvailable={device.updateAvailable}
									/> */}
			{/* <DeviceCardHorizontal
									// maxWidth="50vw"
									key={device.serialNumber}
									device={device.deviceName}
									serialNumber={device.serialNumber}
									firmware={device.firmware}
									language={device.language}
									lastUpdate={device.lastUpdate}
									updateAvailable={device.updateAvailable}
									style={{ cursor: "default" }}
								/>
							</>
						))}
					</SimpleGrid>
				</Box>
			) : (
				<>
				console.log("No devices found")
				</>
			)} */}{" "}
			{/* //TODO: END OF UNUSED CODE */}
			{/* //# EVIS BG IMAGE */}
			<Center>
				<Box
					bgImage={LogoWhite}
					backgroundPosition="center"
					backgroundSize="cover"
					backgroundRepeat="no-repeat"
					mt={"28vh"}
					height={"400px"}
					width={"400px"}
					opacity={0.4}
					zIndex={-1}
				></Box>
			</Center>
		</Box>
	)
}

export default Dashboard
