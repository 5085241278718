import { React, useEffect } from "react"
import { Flex, Image } from "@chakra-ui/react"
import logoEvisMenuToggle from "../../GUI/images/gui/header/logo_EvisMenuToggle.png"

const LogoEvisMenuToggle = (props) => {
	useEffect(() => {
		//^ set logoWhiteIconSVG and logoWhiteTextSVG to local storage
		if (!localStorage.getItem("logoEvisMenuToggle")) {
			localStorage.setItem("logoEvisMenuToggle", logoEvisMenuToggle)
		}
	}, [])

	useEffect(() => {}, [props.isScrolled])

	return (
		<Flex
			{...props}
			alignItems={"center"}
		>
			<Image
				src={localStorage.getItem("logoEvisMenuToggle") || logoEvisMenuToggle}
				height={{ base: "2.35rem", md: "2.6rem", lg: "2.9rem" }}
				width={"auto"}
				alt="logo"
				marginY={{ base: "0.3rem", md: "0.3rem", lg: "0.3rem" }}
			></Image>
		</Flex>
	)
}

export default LogoEvisMenuToggle
