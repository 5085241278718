import { React, useEffect } from "react"
import { Flex, Image } from "@chakra-ui/react"
import logoMineralis from "../../GUI/images/gui/header/logo_mineralis.png"

const LogoMineralis = (props) => {
	useEffect(() => {
		if (!localStorage.getItem("logoMineralis")) {
			localStorage.setItem("logoMineralis", logoMineralis)
		}
	}, [])

	return (
		<Flex
			{...props}
			alignItems={"center"}
		>
			<Image
				src={localStorage.getItem("logoMineralis") || logoMineralis}
				minWidth={{ base: "170px", md: "200px", lg: "250px" }}
				width={{ base: "250px", md: "250px", lg: "300px" }}
				height="auto"
				alt="logo"
				marginY={{ base: "0.3rem", md: "0.3rem", lg: "0.3rem" }}
			></Image>
		</Flex>
	)
}

export default LogoMineralis
