import { React, lazy, useEffect, useState } from "react"
const ParameterEditorView = lazy(
	() => import("../components/Views/EditViews/ParameterEditorView")
)

// ! THIS MAKES NO SENSE BUT IT WORKS - MAKES THE LOADING SCREEN WORK PROPERLY SHOWING "Parameterdaten werden geladen" (from ParameterEditorView) INSTEAD OF "Loading" (from PageParameterEditor)
const PageParameterEditor = () => {
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		setIsLoading(false)
	}, [])

	return <>{isLoading ? <div>Loading</div> : <ParameterEditorView />}</>
}

export default PageParameterEditor
