import React from "react"

import { Box, Spinner, Center } from "@chakra-ui/react"

import "./Overlay.css" // Import CSS file for styling

const Overlay = () => {
	return (
		<div className="overlay">
			<Box>
				<Center>
					<Spinner
						size="xl"
						color="white"
					/>
				</Center>
			</Box>
		</div>
	)
}

export default Overlay
