import React from "react"
import { Box, Text, Flex, Progress } from "@chakra-ui/react"
import { useTranslate } from "react-polyglot"
import theme from "../../theme.js"

const ToastProgressBar = (props) => {
	const t = useTranslate()

	return (
		<Box>
			<Text>
				{props.fileName +
					" " +
					t("Toasts.toastFirmwareSendPending", {
						_: "Firmware wird übertragen"
					})}
			</Text>

			<Progress
				variant="striped"
				hasStripe
				value={props.progress}
				colorScheme={"gray"}
				size="xs"
				isAnimated
				aria-valuenow={props.progress}
			/>
		</Box>
	)
}

export default ToastProgressBar
