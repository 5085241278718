import { encodeAccessDataChangeDir } from "./TlvAccessEncode.js"
import { encodeAccessDataGetFile } from "./TlvAccessEncode.js"
import { encodeAccessDataLs } from "./TlvAccessEncode.js"
import { encodeAccessDataPutFile } from "./TlvAccessEncode.js"
import { encodeAccessDataSendFileslice } from "./TlvAccessEncode.js"
import { encodeAccessrenameGetFile } from "./TlvAccessEncode.js"
import { encodeAccessDataDeleteFile } from "./TlvAccessEncode.js"
import { encodeAccessTransportGetDeviceInfo } from "./TlvAccessEncode.js"
import { encodeAccessTransportSetDeviceTime } from "./TlvAccessEncode.js"
import { encodeAccessTransportResetDevice } from "./TlvAccessEncode.js"

import { decodeAccessData } from "./TlvAccessDecode.js"
import { decodeAccessTransportSystemCtrlAnswer } from "./TlvAccessDecode.js"

import { writeTlvStream } from "../WebSerial/WebSerialWriteTlv.js"

export async function sendLsCmd(instance, port, offset) {
	//encode data and transportlayer
	const encodedData = await encodeAccessDataLs(instance, offset)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessData(instance, accessData)

	return answer
}

export async function sendGetFileCmd(instance, port, filename) {
	//encode data and transportlayer
	const encodedData = await encodeAccessDataGetFile(instance, filename)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessData(instance, accessData)

	return answer
}

export async function sendStartPutCmd(
	instance,
	port,
	filename,
	replace,
	filesize,
	sha256
) {
	//encode data and transportlayer
	const encodedData = await encodeAccessDataPutFile(
		instance,
		filename,
		replace,
		filesize,
		sha256
	)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessData(instance, accessData)
	return answer
}

export async function sendPutFileSliceCmd(
	instance,
	port,
	offset,
	fileSliceSize,
	data
) {
	//encode data and transportlayer
	const encodedData = await encodeAccessDataSendFileslice(
		instance,
		offset,
		fileSliceSize,
		data
	)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessData(instance, accessData)
	return answer
}

export async function sendChangeDirCmd(instance, port, path) {
	//encode data and transportlayer
	const encodedData = await encodeAccessDataChangeDir(instance, path)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessData(instance, accessData)
	return answer
}

export async function sendRenameCmd(instance, port, oldname, newname) {
	//encode data and transportlayer
	const encodedData = await encodeAccessrenameGetFile(
		instance,
		oldname,
		newname
	)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessData(instance, accessData)
	return answer
}

export async function sendDeleteCmd(instance, port, filename) {
	//encode data and transportlayer
	const encodedData = await encodeAccessDataDeleteFile(instance, filename)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessData(instance, accessData)
	return answer
}

export async function sendSystemDeviceInfoCmd(instance, port) {
	//encode data and transportlayer
	const encodedData = await encodeAccessTransportGetDeviceInfo(instance)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessTransportSystemCtrlAnswer(
		instance,
		accessData
	)

	return answer
}

export async function sendDeviceTimeSet(instance, port, unixtime) {
	//encode data and transportlayer
	const encodedData = await encodeAccessTransportSetDeviceTime(
		instance,
		unixtime
	)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessTransportSystemCtrlAnswer(
		instance,
		accessData
	)
	return answer
}

export async function sendDeviceReset(instance, port, wait_ms) {
	//encode data and transportlayer
	const encodedData = await encodeAccessTransportResetDevice(instance, wait_ms)
	//Write to usb serial
	const accessData = await writeTlvStream(port, encodedData)
	// allocate memory for decoding in webassembly
	const answer = await decodeAccessTransportSystemCtrlAnswer(
		instance,
		accessData
	)
	return answer
}
