import React, { useEffect, useState } from "react"
import { PortContext } from "./WebSerialInstanceContext"

function PortProvider({ children }) {
	const [port, setPort] = useState(null)

	useEffect(() => {}, [port])

	return (
		<PortContext.Provider value={[port, setPort]}>
			{children}
		</PortContext.Provider>
	)
}

export default PortProvider
