import axios from "axios"

export const getFirmwareFile = async (versionEndpoint, firmwareNumber) => {
	try {
		const url = "/firmwareFiles"
		const response = await axios.post(url, { versionEndpoint, firmwareNumber })
		return response.data
	} catch (error) {
		console.log(error)
		return error
	}
}
