import React from "react"
import { useLocation } from "react-router-dom"
import { Outlet } from "react-router-dom"
import { Text, Flex, Link } from "@chakra-ui/react"
import ContactDrawer from "../components/Dashboard/ContactDrawer"
import NavbarHeaderView from "../components/Views/NavViews/NavbarHeaderView"
// import _ from "lodash"
import { useTranslate } from "react-polyglot"

const Layout = () => {
	const location = useLocation()

	const t = useTranslate()
	return (
		<>
			<NavbarHeaderView />
			{/* //!DEACTIVATE IN PROD DEPLOY */}
			{process.env.NODE_ENV === "development" && (
				<div style={{ marginTop: "-35px" }}>
					<small style={{ marginLeft: "35px" }}>
						You are running this application in <b>{process.env.NODE_ENV}</b>{" "}
						mode.
					</small>
					<small style={{ marginLeft: "35px" }}>
						<b>
							{process.env.NODE_ENV === "development" &&
								"@api: http://localhost:3000/"}
						</b>
					</small>
					<br />
					<br />
				</div>
			)}

			<Outlet />

			<Flex
				position="absolute"
				bottom={"0"}
				left={"0"}
				as="samp"
				fontSize="sm"
				mb={1}
				ml={3}
				wrap="wrap"
			>
				<br />
				<Flex>
					<Text>&copy;{new Date().getFullYear()}</Text>
					<Link
						ml="1"
						href="https://www.evis.ch/agb/"
						isExternal
						whiteSpace="nowrap"
					>
						<Text
							as="ins"
							whiteSpace="nowrap"
						>
							EVIS AG
						</Text>
					</Link>
					<Text
						ml="1"
						mr="1"
					>
						-
					</Text>
				</Flex>
				<Flex mr={2}>
					<Text whiteSpace="nowrap">
						{t("Layout.rightsDisclaimer", {
							_: "Alle Rechte vorbehalten "
						})}
					</Text>
				</Flex>

				<Flex mr={2}>
					<Text whiteSpace="nowrap">
						<b> Version 12.08.2024</b>
					</Text>
				</Flex>
				{location.pathname === "/" && <ContactDrawer />}
			</Flex>
		</>
	)
}

export default Layout
