import React from "react"

import { Box } from "@chakra-ui/react"

const DecoratorTriangleInputSelect = (props) => {
	return (
		<Box
			w={"45px"}
			h={"36px"}
			marginInlineStart={0}
			ml={"-38px"}
			bg={"bg-default"}
			clipPath="polygon(100% 0%,0% 100%,100% 100%)"
			transition={"all 1.5s ease-in-out"}
		></Box>
	)
}

export default DecoratorTriangleInputSelect
