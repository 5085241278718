import { React, useEffect } from "react"
import { Flex, Image, Text, Link } from "@chakra-ui/react"
import logoEvisMenuToggle from "../GUI/images/gui/header/logo_EvisMenuToggle.png"
import logoEvisMenuToggleDark from "../GUI/images/gui/header/logo_EvisMenuToggle_allWhite.png"
import { useTranslate } from "react-polyglot"

const LogoEvisMenuToggle = (props) => {
	const t = useTranslate()

	useEffect(() => {
		//^ set logoWhiteIconSVG and logoWhiteTextSVG to local storage
		if (!localStorage.getItem("logoEvisMenuToggle")) {
			localStorage.setItem("logoEvisMenuToggle", logoEvisMenuToggle)
		}
		if (!localStorage.getItem("logoEvisMenuToggleDark")) {
			localStorage.setItem("logoEvisMenuToggleDark", logoEvisMenuToggleDark)
		}
	}, [])

	return (
		<Flex
			{...props}
			alignItems={"center"}
		>
			<Link
				href="https://www.evis.ch/"
				isExternal
			>
				<Image
					src={
						localStorage.getItem("chakra-ui-color-mode") === "light"
							? localStorage.getItem("logoEvisMenuToggle") || logoEvisMenuToggle
							: localStorage.getItem("logoEvisMenuToggleDark") ||
								logoEvisMenuToggleDark
					}
					height={{ base: "2rem", md: "2em", lg: "2rem" }}
					width={"auto"}
					alt="logo"
					mr={1.5}
				></Image>
			</Link>

			<Link
				display={{ base: "none", md: "block", lg: "block" }}
				href="https://www.evis.ch/"
				isExternal
				fontSize={{ base: "1rem", md: "1rem", lg: "1rem" }}
				whiteSpace="nowrap"
				mr={2}
			>
				<Text>
					{t("LogoEvisContactDrawer.evisSlogan", {
						_: "EVIS AG Elektronische Systeme"
					})}
				</Text>
			</Link>
		</Flex>
	)
}

export default LogoEvisMenuToggle
