import React from "react";
import NavbarHeader from "../../Navigation/NavbarHeader/NavbarHeader";

const ResponsiveNavbarView = () => {
  return (
    <>
      <NavbarHeader />
    </>
  );
};

export default ResponsiveNavbarView;
