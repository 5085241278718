//# Connect Event Listener

//# EVENT ON CONNECT
export const handleSerialConnect = (
	event,
	setIsConnected,
	setIsPluggedIn,
	openSerialPort,
	toast,
	t
) => {
	toast.info(
		<div>
			<span>
				{t("Toasts.toastReaderPluggedInSpan1", {
					_: "Gerät angeschlossen."
				})}
			</span>{" "}
			<br />
			<span>
				{t("Toasts.toastReaderPluggedInSpan2", {
					_: "Verbindung herstellen."
				})}
			</span>
		</div>,
		{
			limit: 1
		}
	)
	setIsPluggedIn(true)
	toast.clearWaitingQueue()
	//^ add event listener to the button to prevent multiple event listeners on the same button
	document
		.getElementById("connectionStatusButton")
		.addEventListener("click", () => {
			openSerialPort()
		})

	return () => {
		document
			.getElementById("connectionStatusButton")
			.removeEventListener("click", () => {
				openSerialPort()
			})
	}
}

//# EVENT ON DISCONNECT
export const handleSerialDisconnect = (
	port,
	setIsConnected,
	setIsPluggedIn,
	openSerialPort,
	toast,
	t
) => {
	toast.info(
		t("Toasts.toastReaderUnPlugged", {
			_: "Gerät getrennt"
		}),
		{
			// limit: 3
		}
	)

	//^ remove event listener from the button to prevent multiple event listeners on the same button
	document
		.getElementById("connectionStatusButton")
		.removeEventListener("click", () => {
			disconnectDevice(port, setIsConnected, openSerialPort)
		})
	setIsConnected(false)
	setIsPluggedIn(false)
	toast.clearWaitingQueue()

	//# call disconnectDevice function
	disconnectDevice(port, setIsConnected, openSerialPort)
}

//# Disconnect Device
export const disconnectDevice = async (
	port,
	setIsConnected,
	openSerialPort
) => {
	if (document.getElementById("disconnectButton") !== null) {
		document
			.getElementById("disconnectButton")
			.removeEventListener("click", () => {
				openSerialPort()
			})
	}

	if (port && port.readable !== null) {
		document
			.getElementById("connectionStatusButton")
			.removeEventListener("click", () => {
				disconnectDevice()
			})

		setIsConnected(false)

		await port.close()

		document
			.getElementById("connectionStatusButton")
			.addEventListener("click", () => {
				openSerialPort()
			})
		return () => {
			document
				.getElementById("connectionStatusButton")
				.removeEventListener("click", () => {
					openSerialPort()
				})
		}
	}
}
