import { useState, useEffect } from "react";
import myModule from "../utils/FileTransfer/TlvModules/tlvAccessModuleFile.mjs";
import { WebSerialInstanceContext } from "./WebSerialInstanceContext.js";

const WebSerialInstanceProvider = ({ children }) => {
  const [myModuleInstanceWebSerial, setMyModuleInstanceWebSerial] =
    useState(null);

  useEffect(() => {
    myModule().then((instance) => {
      setMyModuleInstanceWebSerial(instance);
    });
  }, []);

  return (
    <WebSerialInstanceContext.Provider value={myModuleInstanceWebSerial}>
      {children}
    </WebSerialInstanceContext.Provider>
  );
};

export default WebSerialInstanceProvider;
