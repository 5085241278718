import React from "react";
import Dashboard from "../../Dashboard/Dashboard";

const DashboardView = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardView;
