let keepReading = true
let reader

export async function readTlv(port) {
	if (port.readable) {
		let tempArr
		let tempLength = 0
		const tlvStart = new Uint8Array(1048)
		try {
			reader = port.readable.getReader()

			let { value, done } = await reader.read()
			tlvStart.set(value)

			let offset = value.length
			port.setSignals({
				dataTerminalReady: true
			})
			if (offset < 3) {
				while (offset < 3) {
					let { value, done } = await reader.read()
					tlvStart.set(value, offset)
					offset = offset + value.length
				}
			}
			let tag = tlvStart[0]
			let length = (tlvStart[1] << 8) | tlvStart[2]

			while (length > offset - 3 && !done) {
				// length in msg +3 for Tag+Length
				let { value, done } = await reader.read()
				tlvStart.set(value, offset)
				offset = offset + value.length
			}
			port.setSignals({
				dataTerminalReady: false
			})
			tempArr = new Uint8Array(length + 3)
			tempArr.set(tlvStart.subarray(0, length + 3))
		} catch (error) {
			// Handle error
			console.log(error)
		} finally {
			// Allow the serial port to be closed later.
			reader.releaseLock()
		}
		return tempArr
	}
}
