import React, { useRef } from "react"
import { Button, Flex, Tooltip } from "@chakra-ui/react"
import { TbFolder } from "react-icons/tb"
//# LOCALIZATION
import { useTranslate } from "react-polyglot"

const FilePickerFirmwareUpdate = ({ onFileSelected, setAlertState }) => {
	const t = useTranslate()
	const fileInputRef = useRef(null)

	const handleFileSelection = (event) => {
		const selectedFile = event.target.files[0]
		onFileSelected(selectedFile)
		//^ read file content
		let response
		const reader = new FileReader()
		reader.onload = function (event) {
			response = event.target.result
			return response
		}
		reader.readAsText(selectedFile)
		console.log(selectedFile.name)
	}

	const openFilePickerFirmwareUpdate = () => {
		fileInputRef.current.click()
		//^ reset the input value after 3 seconds to allow the user to select the same file again
		setTimeout(() => {
			fileInputRef.current.value = ""
		}, 3000)
	}

	return (
		<div>
			<input
				type="file"
				id="fileInput"
				ref={fileInputRef}
				style={{ display: "none" }}
				onChange={handleFileSelection}
			/>
			<Flex>
				<Tooltip
					hasArrow
					placement="top-start"
					openDelay={300}
					closeDelay={200}
					label={t("FilePickerFirmwareUpdate.uploadFromDesktop", {
						_: "Datei von Desktop laden"
					})}
				>
					<Button
						aria-label="Firmware update"
						onClick={openFilePickerFirmwareUpdate}
						maxW={"max-content"}
						background={"btn-primary-hover-bg"}
						_hover={{
							background: "field-addon-bg"
						}}
					>
						<TbFolder fontSize={21} />
					</Button>
				</Tooltip>
			</Flex>
		</div>
	)
}

export default FilePickerFirmwareUpdate
