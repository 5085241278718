import React, { useState, useEffect } from "react"
import { ParameterContext } from "./ApiContext"

function ParameterStore({ children }) {
	const [data, setData] = useState(null)

	const url =
		process.env.NODE_ENV === "production"
			? "/parameterTable"
			: "http://localhost:3000/parameterTable"

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(url, {})
				if (!response.ok) {
					throw new Error("Network response was not ok.")
				}
				const data = await response.json()

				setData(data)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchData()
	}, [url])

	return (
		<ParameterContext.Provider value={[data, setData]}>
			{children}
		</ParameterContext.Provider>
	)
}
export default ParameterStore
