import React from "react"
import { useState, useEffect } from "react"
import {
	Box,
	Flex,
	Text,
	Button,
	Stack,
	Spacer,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItem,
	MenuDivider,
	IconButton,
	useColorMode,
	Portal,
	Tooltip
} from "@chakra-ui/react"

import { NavLink, useNavigate } from "react-router-dom"

import LogoMineralis from "./LogoMineralis"
import headerBG_big from "../../GUI/images/gui/header/bgHeader_big_trans.png"
import headerBG_small from "../../GUI/images/gui/header/bgHeader_small_trans.png"
import LogoEvisMenuToggle from "./LogoEvisMenuToggle"
import "./NavbarHeader.css"
import { useAtom } from "jotai"
import deviceInfoAtom from "../../../api/jotaiAtoms/deviceInfoAtom.js"
import { useConnectionStore } from "../../../api/zustandStores/WebSerialStores/isConnectedStore.js"

//# LOCALIZATION
import useLocalizationStore from "../../../api/zustandStores/localizationStore/localizationStore.js"
import { useTranslate } from "react-polyglot"
// import _ from "lodash"

const NavbarHeader = (props) => {
	const navigate = useNavigate()
	const { isConnected, setIsConnected } = useConnectionStore()
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)

	//# Reset deviceInfo when navigating to Dashboard while disconnected
	const handleDeviceInfoResetHomeRouteOnDisconnected = () => {
		if (!isConnected) {
			setDeviceInfo(null)
		}
	}

	return (
		<NavBackground {...props}>
			<NavBarContainer
				{...props}
				// minWidth="max-content"
				minWidth="300px"
				alignItems="center"
				mt={{ base: "-26px", md: "-34px", lg: "-34px" }}
				gap="2"
			>
				<LogoMineralis
					color={["white", "white", "primary.500", "primary.500"]}
					cursor="pointer"
					ml={{ base: "35px", md: "50px", lg: "70px" }}
					mt={{ base: "4px", md: "12px", lg: "18px" }}
					onClick={() => {
						navigate("/")
					}}
					onMouseUp={handleDeviceInfoResetHomeRouteOnDisconnected}
				/>
				<Spacer />
				<ButtonGroup
					w={{ base: "100%", md: "100%", lg: "100%" }}
					h={{ base: "120px", md: "140px", lg: "160px" }}
					alignItems="center"
					justifyContent={"right"}
					wrap="wrap"
				>
					{/*//# <LinksHeader /> */}
				</ButtonGroup>
				<MenuButtonMain />
			</NavBarContainer>
		</NavBackground>
	)
}

const MenuElement = ({ children, isLast, to = "/", ...rest }) => {
	return (
		<Box>
			<Text
				display="flex"
				fontSize={{ base: "0.9rem", md: "1rem", lg: "1rem " }}
				textAlign={{ base: "center", md: "left" }}
				fontWeight="500"
				textTransform={"uppercase"}
				{...rest}
			>
				{children}
			</Text>
		</Box>
	)
}

const menuLinksStyle = {
	textDecoration: "underline",
	textDecorationColor: "rgba(219, 219, 5, 0.7)",
	textShadow: "0 1px 5px rgba(219, 219, 5, 0.5)",
	// textDecorationShadow: "0 3px 8px rgba(219, 219, 5, 0.5)"
	textUnderlineOffset: "5px"
}

const LinksHeader = ({ isActive }) => {
	const { isConnected, setIsConnected } = useConnectionStore()
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)

	//# Reset deviceInfo when navigating to Dashboard while disconnected
	const handleDeviceInfoResetHomeRouteOnDisconnected = () => {
		if (!isConnected) {
			setDeviceInfo(null)
		}
	}
	return (
		<Box
			display={{ base: "none", md: "block" }}
			flexBasis={{ base: "80%", md: "auto" }}
		>
			<Stack
				gap={4}
				spacing={8}
				align="center"
				justify={["space-between", "space-between", "flex-end", "flex-end"]}
				direction={["column", "column", "row", "row"]}
				pt={[4, 4, 0, 0]}
				mt={[10, 10, 0, 0]}
				pr={4}
				ml={{ base: 16, md: 0, lg: 16 }}
			>
				<MenuElement mx={4}>
					{/* //# Dashboard Link */}
					<NavLink
						to="/"
						onMouseUp={handleDeviceInfoResetHomeRouteOnDisconnected}
						style={({ isActive }) => {
							return isActive
								? {
										color: "#4d4e4d",
										textDecoration: "underline",
										textDecorationColor: "link-text",
										fontWeight: "black",
										textUnderlineOffset: "3px"
									}
								: { color: "#4d4e4d" }
						}}
					>
						Dashboard
					</NavLink>
				</MenuElement>
				<MenuElement mx={4}>
					<NavLink
						to="/parametereditor"
						style={({ isActive }) => {
							return isActive
								? {
										color: "#4d4e4d",
										textDecoration: "underline",
										textDecorationColor: "link-text",
										fontWeight: "black",
										textUnderlineOffset: "3px"
									}
								: { color: "#4d4e4d" }
						}}
					>
						Parameter Editor
					</NavLink>
				</MenuElement>
				<MenuElement mx={4}>
					<NavLink
						to="/itemandpriceeditor"
						style={({ isActive }) => {
							return isActive
								? {
										color: "#4d4e4d",
										textDecoration: "underline",
										textDecorationColor: "link-text",
										fontWeight: "black",
										textUnderlineOffset: "3px"
									}
								: { color: "#4d4e4d" }
						}}
					>
						Artikel & Preis Editor
					</NavLink>
				</MenuElement>
				{/* //TODO: Key Management Link disabled for now */}
				{/* <MenuElement mx={4}>
					<NavLink
						to="/keymanagement"
						style={({ isActive }) => {
							return isActive
								? {
										color: "#4d4e4d",
										textDecoration: "underline",
										textDecorationColor: "link-text",
										fontWeight: "black",
										// textShadow: "0 2px 5px rgba(255, 255, 255, 0.5)",
										textUnderlineOffset: "3px"
									}
								: { color: "#4d4e4d" }
						}}>
						Key Verwaltung
					</NavLink>
				</MenuElement> */}

				{/* <MenuElement to="/">Home</MenuElement> */}
				{/* <MenuElement to="/how">How It works </MenuElement> */}
				{/* <MenuElement to="/faetures">Features </MenuElement> */}
				{/* <MenuElement to="/pricing">Pricing </MenuElement> */}
				{/* //! SIGN IN BUTTON DISABLED FOR NOW */}
				{/* <MenuElement to="/signup" isLast display="none">
							<Button
							aria-label="Sign In"
							isDisabled={true}
							to="/signup"
							rounded="md"
							textTransform={"uppercase"}
							mb={{ base: "1rem", md: "0" }}
							color="darkgrey"
							bg={["white", "white", "primary.500", "primary.500"]}
							_hover={{
							bg: ["primary.100", "primary.100", "primary.600", "primary.600"]
							}}
						>
							Sign In
						</Button>
						</MenuElement> */}
				{/* <MenuButtonMain /> */}
			</Stack>
		</Box>
	)
}

const MenuButtonMain = () => {
	const { colorMode, toggleColorMode } = useColorMode()
	const [isScrolled, setIsScrolled] = useState(false)
	const { isConnected, setIsConnected } = useConnectionStore()
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)

	const { locale, setLocale } = useLocalizationStore()

	const t = useTranslate()

	//# Reset deviceInfo when navigating to Dashboard while disconnected
	const handleDeviceInfoResetHomeRouteOnDisconnected = () => {
		if (!isConnected) {
			setDeviceInfo(null)
		}
	}

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 260) {
				setIsScrolled(true)
			} else {
				setIsScrolled(false)
			}
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	//# LANGUAGE SETTINGS
	const changeLanguage = (language) => {
		//# set localStorage
		localStorage.setItem("locale", language)
		//# set zustand state
		setLocale(language)
	}

	return (
		<Menu
			isLazy
			closeOnSelect={true}
		>
			<MenuButton
				mr={6}
				as={IconButton}
				icon={<LogoEvisMenuToggle isScrolled={isScrolled} />}
				aria-label="Main Menu"
				minW={{ base: "50px", md: "50px", lg: "60px" }}
				bg={"rgba(0, 0, 0, 0)"}
				border={"none"}
				_hover={{
					bg: "rgba(0, 0, 0, 0)"
				}}
				_active={{
					bg: "rgba(0, 0, 0, 0)"
				}}
			/>
			<Portal
				zIndex={"998"}
				mt={-10}
				pt={-10}
			>
				{" "}
				{/* //! makes the menu appear below of the navbar */}
				<MenuList
					zIndex={"998"}
					minWidth="240px"
					pt={isScrolled ? "85px" : "150px"}
					mt={isScrolled ? "-70px" : "-70px"}
				>
					<MenuOptionGroup
						title={t("NavbarHeader.links", {
							_: "Links"
						})}
						fontSize={"xs"}
					>
						<MenuItem
							ml={"10%"}
							maxW={"90%"}
							value="Dashboard"
							fontSize={"sm"}
						>
							<NavLink
								to="/"
								onClick={handleDeviceInfoResetHomeRouteOnDisconnected}
							>
								{/* //# Dashboard */}
								{t("NavbarHeader.dashboard", {
									_: "dashboard"
								})}
							</NavLink>
						</MenuItem>

						<MenuItem
							ml={"10%"}
							maxW={"90%"}
							fontSize={"sm"}
						>
							<NavLink to="/parametereditor">
								{/*^//# Parameter Editor */}
								{t("NavbarHeader.parameterEditor", {
									_: "parameter Editor"
								})}
							</NavLink>
						</MenuItem>

						{isConnected ? (
							<MenuItem
								ml={"10%"}
								maxW={"90%"}
								value="itemEditor"
								fontSize={"sm"}
							>
								<NavLink to="/itemandpriceeditor">
									{/*//# Artikel und Preis Editor connected*/}
									{t("NavbarHeader.itemandpriceEditor", {
										_: "Artikel und Preis Editor"
									})}
								</NavLink>
							</MenuItem>
						) : (
							<>
								<Tooltip
									label={
										localStorage.getItem("locale") === "en"
											? "device not connected"
											: localStorage.getItem("locale") === "fr"
												? "L'appareil n'est pas connecté"
												: " Gerät nicht verbunden"
									}
									hasArrow={true}
									bg="tooltip-bg"
									aria-label="tooltip to connect reader first to access link"
									placement="top-end"
									openDelay={300}
									closeDelay={200}
								>
									<MenuItem
										ml={"10%"}
										maxW={"90%"}
										value="itemEditor"
										fontSize={"sm"}
										// eslint-disable-next-line
										isDisabled
									>
										{/*//# Artikel und Preis Editor disconnected*/}
										{t("NavbarHeader.itemandpriceEditor", {
											// name: "itemandpriceEditor",
											_: "Artikel und Preis Editor"
										})}
									</MenuItem>
								</Tooltip>
							</>
						)}

						{/* //TODO: Key Management Link disabled for now */}

						{isConnected ? (
							<MenuItem
								ml={"10%"}
								maxW={"90%"}
								value="itemEditor"
								fontSize={"sm"}
							>
								<NavLink to="/keymanagement">
									{/* //# Key Verwaltung */}
									{t("NavbarHeader.keyManagement", {
										_: "Key Verwaltung"
									})}
								</NavLink>
							</MenuItem>
						) : (
							<>
								<Tooltip
									label={
										localStorage.getItem("locale") === "en"
											? "device not connected"
											: localStorage.getItem("locale") === "fr"
												? "L'appareil n'est pas connecté"
												: " Gerät nicht verbunden"
									}
									hasArrow={true}
									bg="tooltip-bg"
									aria-label="tooltip to connect reader first to access link"
									placement="top-end"
									openDelay={300}
									closeDelay={200}
								>
									<MenuItem
										ml={"10%"}
										maxW={"90%"}
										value="itemEditor"
										fontSize={"sm"}
										// eslint-disable-next-line
										isDisabled
									>
										<Text>
											{/* //# Key Verwaltung */}
											{t("NavbarHeader.keyManagement", {
												_: "Key Verwaltung"
											})}
										</Text>
									</MenuItem>
								</Tooltip>
							</>
						)}
					</MenuOptionGroup>
					<MenuDivider />
					<MenuOptionGroup>
						{/* //# THEME SWITCH */}
						<MenuItem
							ml={"10%"}
							maxW={"90%"}
							fontSize={"sm"}
							value="light"
							// type="checkbox"
							onClick={toggleColorMode}
						>
							{/*//^ THEME SWITCH TEXTS*/}
							{colorMode === "light"
								? t("NavbarHeader.themeDark", {
										_: "Dark "
									})
								: t("NavbarHeader.themeLight", {
										_: "Light "
									})}{" "}
							{t("NavbarHeader.theme", {
								_: " Theme"
							})}
						</MenuItem>
					</MenuOptionGroup>
					<MenuDivider />
					{/* //# LOCALIZATION */}
					<MenuOptionGroup>
						{/* // eslint-disable-next-line */}
						<MenuItem
							ml={"10%"}
							maxW={"90%"}
							fontSize={"sm"}
							value="light"
						>
							<Button
								variant={"localization"}
								onClick={() => changeLanguage("de")}
								isActive={locale === "de"}
								value={"de"}
							>
								DE
							</Button>
							<Button
								variant={"localization"}
								onClick={() => changeLanguage("fr")}
								isActive={locale === "fr"}
								value={"fr"}
							>
								FR
							</Button>
							<Button
								variant={"localization"}
								onClick={() => changeLanguage("en")}
								isActive={locale === "en"}
								value={"en"}
							>
								EN
							</Button>
						</MenuItem>
					</MenuOptionGroup>
					{/* //TODO: Account Links disabled for now */}
					{/* <MenuDivider /> */}
					{/* <MenuOptionGroup
						title="Account"
						type="radio"
						fontSize={"xs"}>
						<MenuItem
							ml={"10%"}
							maxW={"90%"}
							value="profile"
							fontSize={"sm"}
							isDisabled>
							Benutzerprofil
						</MenuItem>



						<MenuItem
							ml={"10%"}
							maxW={"90%"}
							value="settings"
							fontSize={"sm"}
							isDisabled>
							Einstellungen
						</MenuItem>

						<Select
							variant={"ghost"}
							ml={"20px"}
							maxW={"85%"}
							mt={-1}
							fontSize={"sm"}
							// pt={-1}
							mb={2}
							isDisabled>
							<option value="de">Deutsch</option>
							<option value="en">English</option>
							<option value="fr">Français</option>
						</Select>
						<Center>
							<Button
								variant="solid"
								aria-label="Sign Out"
								isDisabled
								w={"75%"}
								textTransform={"uppercase"}>
								<NavLink to="/signup">Log Out</NavLink>
							</Button>
						</Center>
					</MenuOptionGroup> */}
					{/* </MenuOptionGroup> */}
				</MenuList>
			</Portal>
		</Menu>
	)
}

const NavBarContainer = ({ children, ...props }) => {
	return (
		<Flex
			{...props}
			as="nav"
			position="fixed"
			top={0}
			right={0}
			zIndex={1000}
			w={{ base: "100%", md: "100%", lg: "100%" }}
			h={{ base: "120px", md: "140px", lg: "160px" }}
		>
			{children}
		</Flex>
	)
}

const NavBackground = ({ ...props }) => {
	const [isScrolled, setIsScrolled] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 260) {
				setIsScrolled(true)
			} else {
				setIsScrolled(false)
			}
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	return (
		<>
			<Flex
				{...props}
				className={isScrolled ? "scrolled" : "not-scrolled"}
				position="fixed"
				// top={0}
				top={
					isScrolled
						? { base: "0", md: "0", lg: "0" }
						: { base: "0", md: "0", lg: "-3" }
				}
				right={0}
				zIndex={999}
				backgroundImage={isScrolled ? headerBG_small : headerBG_big}
				backgroundSize={"cover"}
				backgroundRepeat={"no-repeat"}
				backgroundPosition={"end"}
				backgroundClip={"border-box"}
				backgroundBlendMode={"multiply"}
				w={{ base: "100%", md: "100%", lg: "100%" }}
				h={
					isScrolled
						? { base: "115px", md: "125px", lg: "110px" }
						: { base: "115px", md: "125px", lg: "170px" }
				}
				transition={"all linear 0.1s"}
			></Flex>
			{/* //# GRADIENT HIDE BOX FOR NAVBAR */}
			<Box
				{...props}
				className={"opacity-gradient"}
				position="fixed"
				top={0}
				left={0}
				zIndex={997}
				w={{ base: "100%", md: "100%", lg: "100%", xl: "85%" }}
				h={
					isScrolled
						? { base: "115px", md: "115px", lg: "120px", xl: "110px" }
						: { base: "115px", md: "115px", lg: "160px", xl: "170px" }
				}
				transition={"all ease-in-out 0.5s"}
			></Box>
		</>
	)
}

export default NavbarHeader
