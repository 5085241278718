import React, { useState, useEffect } from "react"
import { ApiContext, ConditionContext } from "./ApiContext"

function ConditionStore({ children }) {
	const [conditionData, setConditionData] = useState(null)

	const url =
		process.env.NODE_ENV === "production"
			? "/ConditionTable"
			: "http://localhost:3000/ConditionTable"

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(url, {
					priority: "high",
					params: {
						fields:
							"paranr,length,defaultval, format_id,doc_id,fixedvalue_id,folder_id,rank,condition_id"
					}
				})
				if (!response.ok) {
					throw new Error("Network response was not ok.")
				}
				const data = await response.json()

				setConditionData(data)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchData()
	}, [url])

	return (
		<ConditionContext.Provider value={[conditionData, setConditionData]}>
			{children}
		</ConditionContext.Provider>
	)
}
export default ConditionStore
