//# WRITE STREAM TO SERIAL DEVICE
import { readTlv } from "./WebSerialReadTlv.js"
import { toast } from "react-toastify"

export const writeTlvStream = async (port, data) => {
	// console.log(port)
	// console.log(data)
	// eslint-disable-next-line no-undef
	let data2

	//if length of data % 64 is ==0  usb doesn't know if transfer is done we have to add [0,0,0] which is tag 0 length 0
	if (data.length % 64 === 0) {
		data2 = new Uint8Array(data.length + 3)
		data2.set(data)
		console.log(data)
		data2.set([0, 0, 0], data.length)
		console.log(data2)
	} else {
		data2 = new Uint8Array(data.length)
		data2.set(data)
	}

	try {
		const writer = port.writable.getWriter()

		await writer.write(data2)
		// Allow the serial port to be closed later.
		writer.releaseLock()
		port.setSignals({
			dataTerminalReady: true
		})
		let answer = await readTlv(port)
		port.setSignals({
			dataTerminalReady: false
		})
		// toast.success("Sent successfully!")
		// console.log(answer)
		return answer
	} catch (error) {
		console.log(error)
		const message = `Gerät verbinden.`
		toast.error(message)
	}
}
