import React, { Suspense } from "react"
import DotSpinner from "../components/GUI/DotSpinner.jsx"
import DashboardView from "../components/Views/DashboardView/DashboardView"

const PageDashboard = () => {
	return (
		<>
			<Suspense fallback={<DotSpinner />}>
				<DashboardView />
			</Suspense>
		</>
	)
}

export default PageDashboard
