import { create } from "zustand"
import browserLocales from "../../localization/browserLocalization"

const useLocalizationStore = create((set) => ({
	locale: localStorage.getItem("locale") || browserLocales() || "de",

	//#set zustand state
	setLocale: (locale) => set({ locale })
}))

export default useLocalizationStore
