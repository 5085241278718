import React, {
	useContext,
	useEffect,
	useCallback,
	useRef,
	useState
} from "react"
import { Flex, Select, Text } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import DecoratorTriangleInputSelect from "../../GUI/DecoratorTriangleInputSelect.jsx"
import myModule from "../../../utils/FileTransfer/TlvModules/tlvAccessModuleFile.mjs"
import { putFile } from "../../../utils/FileTransfer/TlvAccessProtocol/TlvFiletransfer.js"
import { sendDeleteCmd } from "../../../utils/FileTransfer/TlvAccessProtocol/TlvAccessCmd.js"
import { useQuery } from "@tanstack/react-query"
import { getFirmwareReleasesForDeviceFirmwareNumber } from "../../../api/fetchHooks/getFirmwareReleasesForDeviceFirmwareNumber.js"
import { getFirmwareFile } from "../../../api/fetchHooks/getFirmwareFile.js"
import { useAtom } from "jotai"
import progressAtom from "../../../api/jotaiAtoms/progressAtom.js"
import deviceInfoAtom from "../../../api/jotaiAtoms/deviceInfoAtom.js"
import { PortContext } from "../../../contexts/WebSerialInstanceContext.js"
import { toast } from "react-toastify"
import ToastProgressBar from "../../GUI/ToastProgressBar.jsx"
//# LOCALIZATION
import { useTranslate } from "react-polyglot"
// import { set } from "lodash"
import DecoratorTriangleInputSelectDashboardButtons from "../../GUI/DecoratorTriangleInputSelectDashboardButtons.jsx"
import isBlockingInteractionAtom from "../../../api/jotaiAtoms/isBlockingInteraction.js"

const LegicOsSelectorSelector = (props) => {
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)
	const [versionEndpoint, setVersionEndpoint] = React.useState(null) //TODO: make this a wildcard to display all firmware versions when no device is connected
	const [port, setPort] = useContext(PortContext)
	const [isBlockingInteraction, setIsBlockingInteraction] = useAtom(
		isBlockingInteractionAtom
	)
	const t = useTranslate()

	//# TOAST PROGRESS BAR FOR FIRMWARE UPLOAD STATUS
	//^ setProgress is callback-function from putFile in TlvFiletransfer.js to update the progress state
	//^ progress is the state to update the progress bar
	//^toast is initialized below, then updated with updsate() function and rerendered with useEffect
	const [progress, setProgress] = useAtom(progressAtom)
	// console.log(progress)
	const toastId = useRef(null)
	const [currentFileName, setCurrentFileName] = useState(null)

	const update = () => {
		if (progress < 100) {
			toast.update(toastId.current, {
				type: "info",
				theme: "light",
				render: (
					<ToastProgressBar
						progress={progress}
						fileName={currentFileName}
					/>
				)
			})
		} else {
			toast.update(toastId.current, {
				render: (
					<Text>
						{t("Toasts.toastFirmwareSendSuccess", {
							_: "Firmware übertragen"
						})}
					</Text>
				),
				autoClose: 3000,
				type: "success",
				theme: "colored",
				isLoading: false,
				hideProgressBar: true,
				limit: 1
			})
			toast.clearWaitingQueue()
		}
	}
	useEffect(() => {
		update()
	}, [progress])
	//^ END TOAST PROGRESS BAR - toast initialized below

	//# THIS I NEED THIS TO UPDATE AND RERENDER THE SELECT DROPDOWN
	useEffect(() => {
		//^ rerender for deviceInfo
	}, [deviceInfo])

	useEffect(() => {
		const firmwareNumber = "OS50" //! HARDCODED FOR NOW
		const fetchData = async () => {
			if (
				versionEndpoint !== null &&
				versionEndpoint !== undefined &&
				firmwareNumber !== null &&
				firmwareNumber !== undefined
			) {
				const firmwareFiles = await getFirmwareFile(
					versionEndpoint,
					firmwareNumber
				)
				if (firmwareFiles.length <= 0 || firmwareFiles === undefined) {
					getFirmwareFile(versionEndpoint, firmwareNumber || wildcard)
					return
				} else {
					handleFirmwareFileSelected(firmwareFiles[0].Files)
				}
			}
		}

		fetchData()
	}, [versionEndpoint, setVersionEndpoint])

	//# useQuery to get the firmware version from MongoDB and display it in the dropdown
	const wildcard = new RegExp(".*")

	const { data: legicOsReleasesForDeviceFirmwareNumber } = useQuery({
		queryKey: [
			"legicOsReleasesForDeviceFirmwareNumber",
			deviceInfo?.firmwareNumber
		],
		queryFn: async () => {
			return getFirmwareReleasesForDeviceFirmwareNumber(
				"OS50" //! HARDCODED FOR NOW, WHEN MORE FIRMWARE-TYPES ARE AVAILABLE, THIS WILL BE A VARIABLE
			)
		},
		staleTime: process.env.NODE_ENV === "production" ? 1000 * 3 : 1000 * 3,
		enabled: !!deviceInfo && deviceInfo.firmwareNumber !== null
	})

	useEffect(() => {
		//^ rerender LegicOS selector
	}, [legicOsReleasesForDeviceFirmwareNumber])

	//# useQuery to get the firmware file for the selected version
	//^ query key and query function need to be defined outside of the event handler
	const versionEndpointQueryKey = "firmwareFiles"

	const {
		data: firmwareFiles,
		isLoading,
		isError
	} = useQuery({
		queryKey: [versionEndpointQueryKey],
		queryFn: async () => await getFirmwareFile(versionEndpoint),
		staleTime: process.env.NODE_ENV === "production" ? 1000 * 60 * 5 : 1000 * 3,
		enable: versionEndpoint !== null && versionEndpoint !== undefined //! TODO: NOT TRIGGERING YET - Enabkle when deviceInfo and everything is ready for PROD (device will be connected always when this data is needed)
	})

	//# SEND FIRMWARE UPDATE TO DEVICE SEQUENTIALLY
	const handleFirmwareFileSelected = async (selectedFiles) => {
		//^  if device is not connected, show error toast
		if (port === undefined) {
			toast.error(
				t("Toasts.toastReaderConnectionError", {
					_: "Gerät nicht verbunden."
				})
			)
			return
		}
		setIsBlockingInteraction(true)
		for (const currentFile of selectedFiles) {
			try {
				await new Promise((resolve, reject) => {
					console.log("currentFile", currentFile)
					setCurrentFileName(currentFile.Name)

					const base64String = currentFile.Filestream

					//! WORKS FOR MOST FILES, NOT FOR LEGIC OS FILES (.bin)____________
					const decodeBase64 = (base64String) => {
						try {
							return new Uint8Array(
								atob(base64String)
									.split("")
									.map((c) => c.charCodeAt(0))
							)
						} catch (err) {
							console.error("Failed to decode Base64 string:", err)
							return null
						}
					}
					const binaryData = decodeBase64(base64String)

					myModule()
						.then(async (instance) => {
							// eslint-disable-next-line no-unused-vars
							const answer = await sendDeleteCmd(
								instance,
								port,
								"update/" + currentFile.Name
							)
							//^ progress needs to be reaset to 0 for each file here bc small files will only trigger 100 right away
							//^ setting 0 here forces a rerender, bc the progress bar is only updated when progress changes (and it is already 100 from the last file)
							setProgress(0)
							// eslint-disable-next-line no-unused-vars
							const fileData = await putFile(
								instance,
								port,
								"update/" + currentFile.Name,
								true,
								binaryData,
								setProgress
							)
							// 	{
							// 		pending:
							// 			currentFile.Name +
							// 			" " +
							// 			t("Toasts.toastFirmwareSendPending", {
							// 				_: "Firmware wird Übertragen."
							// 			}),
							// 		success:
							// 			currentFile.Name +
							// 			" " +
							// 			t("Toasts.toastFirmwareSendSuccess", {
							// 				_: "Firmware erfolgreich Übertragen."
							// 			}),
							// 		error:
							// 			currentFile.Name +
							// 			" " +
							// 			t("Toasts.toastFirmwareSendError", {
							// 				_: "Firmwareupdate fehlgeschlagen."
							// 			})
							// 	},
							// 	{
							// 		limit: 4,
							// 		progress: undefined,
							// 		closeOnClick: false
							// 	},
							// 	toast.clearWaitingQueue()
							// )

							//^ Initialize the toast with a pending status
							toastId.current = toast.loading(
								t("Toasts.toastFirmwareSendPreparing", {
									_: "Firmware wird vorbereitet"
								}),
								{
									type: "info",
									theme: "light",
									limit: 1
								}
							)
							// eslint-disable-next-line no-unused-vars
							const response = await putFile(
								instance,
								port,
								"update/" + currentFile.Name,
								true,
								new Uint8Array(binaryData),
								setProgress
							)

							resolve()
						})
						.catch((error) => {
							console.log(error)
							reject()
						})
				})
			} catch (error) {
				console.log(error)
				setIsBlockingInteraction(false)
				toast.error("Es ist ein Fehler aufgetreten.")
			} finally {
				//^ not needed for now
				setIsBlockingInteraction(false)
			}
		}
		toast.clearWaitingQueue()
	}

	const handleFirmwareVersionChange = useCallback(
		async (e) => {
			//^ get the value of the selected dropdown option and update the state "versionEndpoint" with it
			setVersionEndpoint(e.target.value)
			await firmwareFiles
		},
		[setVersionEndpoint, firmwareFiles]
	)

	if (isLoading)
		return (
			<div style={{ marginTop: "0px", marginLeft: "10px" }}>
				<Select
					width={"110px"}
					h={"24px"}
					cursor={"pointer"}
					fontSize={"0.92rem"}
					icon={
						<ChevronDownIcon
							display="flex"
							mb={"2px"}
							ml={"5px"}
							mt={"1px"}
						/>
					}
					placeholder={t("LegicOsSelector.selectButtonPlaceholder", {
						_: "Legic OS"
					})}
				></Select>
				<DecoratorTriangleInputSelect
					backgroundColor="btn-primary-hover-bg"
					borderColor="btn-primary-border"
				/>
			</div>
		)
	if (isError) return <div>Error fetching data</div>

	return (
		<div>
			<Flex>
				<Select
					width={"110px"}
					h={"24px"}
					cursor={"pointer"}
					fontSize={"0.92rem"}
					icon={
						<ChevronDownIcon
							display="flex"
							mb={"2px"}
							ml={"5px"}
							mt={"1px"}
						/>
					}
					placeholder={t("LegicOsSelector.selectButtonPlaceholder", {
						_: "Legic OS"
					})}
					value={deviceInfo?.firmwareNumber.toString()}
					onChange={(e) => handleFirmwareVersionChange(e)}
				>
					{legicOsReleasesForDeviceFirmwareNumber !== undefined &&
					legicOsReleasesForDeviceFirmwareNumber.length !== 0 ? (
						legicOsReleasesForDeviceFirmwareNumber?.map((firmwareRelease) => (
							<option
								key={firmwareRelease._id}
								value={firmwareRelease.Version}
							>
								OS {firmwareRelease.Version}
							</option>
						))
					) : (
						<option>
							{t("LegicOsSelector.selectButtonNoFW", {
								_: "Keine Firmware verfügbar"
							})}
						</option>
					)}
				</Select>
				<DecoratorTriangleInputSelectDashboardButtons
					backgroundColor="btn-primary-hover-bg"
					borderColor="btn-primary-border"
				/>
			</Flex>
		</div>
	)
}

export default LegicOsSelectorSelector
