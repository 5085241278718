import React, { useState } from "react"
import { DeviceInfoContext } from "./WebSerialInstanceContext"

const DeviceInfoProvider = ({ children }) => {
	const [deviceInfo, setDeviceInfo] = useState(null)

	return (
		<DeviceInfoContext.Provider value={{ deviceInfo, setDeviceInfo }}>
			{children}
		</DeviceInfoContext.Provider>
	)
}

export default DeviceInfoProvider
