import React, { useContext, useRef, useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import {
	Flex,
	IconButton,
	Tooltip,
	Tabs,
	Tab,
	TabIndicator,
	TabList,
	Text,
	Divider
} from "@chakra-ui/react"
import { RxDashboard } from "react-icons/rx"
import { TbPlugConnected, TbPlugConnectedX } from "react-icons/tb"
import { useAtom } from "jotai"
import deviceInfoAtom from "../../../api/jotaiAtoms/deviceInfoAtom.js"
import isValidAtom from "../../../api/jotaiAtoms/isValidAtom.js"
import { useConnectionStore } from "../../../api/zustandStores/WebSerialStores/isConnectedStore.js"
import { PortContext } from "../../../contexts/WebSerialInstanceContext.js"
//# LOCALIZATION
import useLocalizationStore from "../../../api/zustandStores/localizationStore/localizationStore.js"
import { useTranslate } from "react-polyglot"

const TabNav = (props) => {
	const navigate = useNavigate()
	const [port, setPort] = useContext(PortContext)
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)
	const { isConnected, setIsConnected } = useConnectionStore()
	const { locale, setLocale } = useLocalizationStore()
	const t = useTranslate()
	const previousDeviceInfo = useRef(
		deviceInfo !== null ? deviceInfo?.hardwarePrintNr : 0
	)
	const [isValid, setIsValid] = useAtom(isValidAtom)

	//# Reset deviceInfo when navigating to Dashboard while disconnected
	const handleDeviceInfoResetHomeRouteOnDisconnected = () => {
		if (!isConnected) {
			setDeviceInfo(null)
			setPort({ readable: null })
		}
	}

	//# Navigate to Dashboard when newly connected device does not match deviceInfo from previous connection
	//# HARDWARE SWITCH
	useEffect(() => {
		//^ navigate back to dashboard when deviceInfo?.hardwarePrintNr changes
		if (isConnected && deviceInfo) {
			if (
				previousDeviceInfo.current !== null &&
				previousDeviceInfo.current !== 0
			) {
				if (deviceInfo?.hardwarePrintNr !== previousDeviceInfo.current) {
					//TODO: when hardwarePrintNr changes, reset isValid to make send button initially active for new device
					setIsValid(true)
					console.log("setIsValid(true)")
					//^ hardwarePrintNr changed
					toast.warning(
						t("Toasts.toastReaderHardwareSwitched", {
							_: "Hardwaregruppenwechsel "
						}) + ` (${deviceInfo?.hardwarePrintNr})`
					)
					navigate("/")
				}
			} else {
				//^ hardwarePrintNr did not change
				setDeviceInfo(deviceInfo) // to persist current deviceInfo on dashboard
				// toast.warning(`Hardwaregruppenwechsel (${deviceInfo?.hardwarePrintNr})`)
			}
		}
	}, [deviceInfo, previousDeviceInfo])

	return (
		<>
			{/* //# TabNavigation and Connection Status */}
			<Flex mt={"6"}>
				{props.isConnected ? (
					//# CONNECTION STATUS ON / DISCONNECT BUTTON
					<Flex
						alignContent={"end"}
						alignItems={"center"}
						borderBottom={"2px solid var(--chakra-colors-chakra-border-color)"}
					>
						<IconButton
							alignContent={"center"}
							background={"transparent"}
							color={"btn-connected-bg"}
							_hover={{ color: "btn-connected-bg" }}
							minW="40px"
							minH="25px"
							fontSize="28px"
							border={"none"}
							onClick={() =>
								props
									.disconnectDevice(
										props.port,
										setIsConnected,
										props.openSerialPort
									)
									.then(() =>
										toast.info(
											t("Toasts.toastReaderDisconnected", {
												_: "Gerät getrennt."
											})
										)
									)
							}
							onMouseUp={() =>
								props.defaultIndex === 0 ? setDeviceInfo(null) : null
							}
							icon={
								<TbPlugConnected
									padding={"15px"}
									id="connectionStatusButton"
									aria-label="disconnect serial device"
									minW="auto"
									border="none"
								/>
							}
						/>
						{/* //TODO: UNUSED CONNECTION STATUS INDICATOR */}
						{/* <Flex>
						<Button
						variant="statusConnected"
						id="disconnectButton"
						aria-label="disconnect serial device"
						minW="auto"
						onClick={() => props.disconnectDevice(props.port)}
						>
						Gerät verbunden
						</Button>
						<DecoratorTriangleButtons
						backgroundColor="btn-connected-bg"
						borderColor="btn-connected-border"
						/>
					</Flex> */}
					</Flex>
				) : (
					//# CONNECTION STATUS OFF / CONNECT BUTTON
					<Flex
						alignContent={"end"}
						alignItems={"center"}
					>
						<IconButton
							background={"transparent"}
							color={"btn-disconnected-bg"}
							variant="statusDisconnected"
							aria-label="connect serial device"
							_hover={{ color: "btn-disconnected-bg" }}
							onClick={() =>
								props.openSerialPort().then(() => setDeviceInfo(deviceInfo))
							}
							minW="40px"
							minH="25px"
							fontSize="28px"
							border={"none"}
							icon={
								<TbPlugConnectedX
									id="connectionStatusButton"
									aria-label="connect serial device"
								/>
							}
						/>
					</Flex>
				)}

				{/* //# TabNavigation */}
				<Tabs
					defaultIndex={props.defaultIndex}
					position="relative"
					variant="unstyled"
					mb={"0"}
					mr={"1"}
				>
					<TabList
						display={"flex"}
						flexWrap="wrap"
						flexDir={"row"}
					>
						<NavLink
							to="/"
							style={{ whiteSpace: "nowrap" }}
						>
							<Tab
								_selected={{ color: "field-default-text" }}
								fontSize={"0.9rem"}
							>
								{/* //# Dashboard Link */}
								<RxDashboard
									fontSize={"22px"}
									onClick={handleDeviceInfoResetHomeRouteOnDisconnected}
								/>
							</Tab>
						</NavLink>
						<NavLink
							to="/parametereditor"
							style={{ whiteSpace: "nowrap" }}
						>
							<Tab
								isSelected
								_selected={{ color: "field-default-text" }}
								fontSize={"0.9rem"}
							>
								{/* //^Parameter Editor */}
								{t("TabNav.parameterEditor", {
									_: "parameter Editor"
								})}
							</Tab>
						</NavLink>
						{props.isConnected ? (
							<NavLink
								to="/itemandpriceeditor"
								style={{ whiteSpace: "nowrap" }}
							>
								<Tab
									_selected={{ color: "field-default-text" }}
									fontSize={"0.9rem"}
								>
									{/*//^ Artikel und Preis Editor  connected*/}
									{t("TabNav.itemandpriceEditor", {
										_: "Artikel und Preis Editor"
									})}
								</Tab>
							</NavLink>
						) : (
							<>
								<Tooltip
									// label={t("TabNav.tooltipConnectReader", {
									// 	_: "Bitte zuerst Gerät verbinden"
									// })}
									label={
										localStorage.getItem("locale") === "en"
											? "device not connected"
											: localStorage.getItem("locale") === "fr"
												? "L'appareil n'est pas connectÃ©"
												: " Gerät nicht verbunden"
									}
									hasArrow={true}
									bg="tooltip-bg"
									aria-label="tooltip showing current configuration format"
									placement="top-end"
									openDelay={300}
									closeDelay={200}
								>
									<Tab
										_selected={{ color: "field-default-text" }}
										fontSize={"0.9rem"}
										// eslint-disable-next-line
										isDisabled
									>
										<Text
											to="/itemandpriceeditor"
											style={{ whiteSpace: "nowrap" }}
											// eslint-disable-next-line
											isDisabled
										>
											{/*//^ Artikel und Preis Editor  disconnected*/}
											{t("TabNav.itemandpriceEditor", {
												_: "Artikel und Preis Editor"
											})}
										</Text>
									</Tab>
								</Tooltip>
							</>
						)}
						{props.isConnected ? (
							<NavLink
								to="/keymanagement"
								style={{ whiteSpace: "nowrap" }}
							>
								{" "}
								<Tab
									_selected={{ color: "field-default-text" }}
									fontSize={"0.9rem"}
								>
									{/* //^ Key Verwaltung */}
									{t("TabNav.keyManagement", {
										_: "Key Verwaltung"
									})}
								</Tab>
							</NavLink>
						) : (
							<>
								<Tooltip
									label={
										localStorage.getItem("locale") === "en"
											? "device not connected"
											: localStorage.getItem("locale") === "fr"
												? "L'appareil n'est pas connectÃ©"
												: " Gerät nicht verbunden"
									}
									hasArrow={true}
									aria-label="tooltip showing current configuration format"
									placement="top-end"
									openDelay={300}
									closeDelay={200}
								>
									<Tab
										_selected={{ color: "field-default-text" }}
										fontSize={"0.9rem"}
										// eslint-disable-next-line
										isDisabled
									>
										<Text
											to="/keymanagement"
											style={{ whiteSpace: "nowrap" }}
											// eslint-disable-next-line
											isDisabled
										>
											{/* //^ Key Verwaltung */}
											{t("TabNav.keyManagement", {
												_: "Key Verwaltung"
											})}
										</Text>
									</Tab>
								</Tooltip>
							</>
						)}
					</TabList>
					<TabIndicator
						display={{ base: "none", md: "flex" }}
						style={{ pointerEvents: "none" }}
						mt="-2px"
						height="2px"
						bg="evisgreen"
						color="field-default-text"
					/>
				</Tabs>
			</Flex>
			<Divider
				maxWidth={"650px"}
				transform={"translateY(-1px)"}
			/>
		</>
	)
}

export default TabNav
