import React, { useState, useEffect } from "react"
import { DocParameterContext } from "./ApiContext"

function DocParameterStore({ children }) {
	const [docParameterData, setDocParameterData] = useState(null)

	const url =
		process.env.NODE_ENV === "production"
			? "/doc_parameterTable"
			: "http://localhost:3000/doc_parameterTable"

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(url, {
					priority: "high",
					params: {
						fields: "doc_id, language, title, comment, description"
					}
				})
				if (!response.ok) {
					throw new Error("Network response was not ok.")
				}
				const data = await response.json()

				setDocParameterData(data)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchData()
	}, [url])

	return (
		<DocParameterContext.Provider
			value={[docParameterData, setDocParameterData]}
		>
			{children}
		</DocParameterContext.Provider>
	)
}
export default DocParameterStore
