import React, { useState } from "react"
import IsValidContext  from "./IsValidContext"

const IsValidProvider = ({ children }) => {
  const [isValid, setIsValid] = useState(null)

  return (
    <IsValidContext.Provider value={{ isValid, setIsValid }}>
      {children}
    </IsValidContext.Provider>
  )
}

export default IsValidProvider
