import React from "react"

import "./DotSpinner.css"

const DotSpinner = (props) => {
	return (
		<div class="dotcontainer">
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
		</div>
	)
}

export default DotSpinner
