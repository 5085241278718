import axios from "axios"

export const getFirmwareReleasesForDeviceFirmwareNumber = async (
	firmwareNumber
) => {
	try {
		const url = "/firmwareReleasesForDeviceFirmwareNumber"
		const response = await axios.post(url, { firmwareNumber })

		return response.data
	} catch (error) {
		console.log(error)
		return error
	}
}
