import React from "react"

import { Box, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react"
import { useTranslate } from "react-polyglot"

//# COMPONENT
const WebSerialButtonsErrorFallback = (props) => {
	const t = useTranslate()

	return (
		<Box
			maxWidth={{ base: "auto", md: "auto" }}
			px={4}
			mr={4}
		>
			<Alert
				status="error"
				mt={4}
			>
				<AlertIcon />
				<AlertTitle pr={2}>
					{t("Fallback.webserialMobileWarning", {
						_: "Verwenden Sie GOOGLE CHROME oder MICROSOFT EDGE auf einem Windows Computer um eine Verbindung zum Gerät herzustellen."
					})}
				</AlertTitle>
			</Alert>
		</Box>
	)
}

export default WebSerialButtonsErrorFallback
