import React, { useState } from "react"
import { ConnectionContext } from "./WebSerialInstanceContext.js"

const ConnectionProvider = ({ children }) => {
	const [isConnected, setIsConnected] = useState(false)

	return (
		<ConnectionContext.Provider value={{ isConnected, setIsConnected }}>
			{children}
		</ConnectionContext.Provider>
	)
}

export default ConnectionProvider
