export async function encodeAccessDataLs(instance, offset) {
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	// let offset

	//encode message for ls
	let result = instance._encode_tlv_access_data_LsReq(
		outPtr,
		2000,
		actSizePtr,
		offset
	)

	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")

		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	//copy data because after free of outpzt it will be lost
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	return encodedData
}

export async function encodeAccessDataVersionReq(instance, version) {
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output

	let result = instance._encode_tlv_access_data_version(
		outPtr,
		2000,
		actSizePtr,
		version
	)
	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")

		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	return encodedData
}

export async function encodeAccessDataChangeDir(instance, dirName) {
	const encoder = new TextEncoder()
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	// convert string to uint8_t ascii
	const dir = encoder.encode(dirName)
	//copy uint8Array to Webassembly memory
	const dirPtr = instance._malloc(dir.length + 1)
	instance.HEAPU8.set(dir, dirPtr)
	instance.HEAPU8[dirPtr + dir.length] = 0
	//encode msg
	let result = instance._encode_tlv_access_data_ChangeDir(
		outPtr,
		2000,
		actSizePtr,
		dirPtr
	)
	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")
		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	instance._free(dirPtr)
	return encodedData
}

export async function encodeAccessDataGetFile(instance, filename) {
	const encoder = new TextEncoder()
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	// convert string to uint8_t ascii
	const name = encoder.encode(filename)
	//copy uint8Array to Webassembly memory
	const filenamePtr = instance._malloc(name.length + 1)
	instance.HEAPU8.set(name, filenamePtr)
	instance.HEAPU8[filenamePtr + name.length] = 0
	//encode msg
	let result = instance._encode_tlv_access_data_GetFile(
		outPtr,
		2000,
		actSizePtr,
		filenamePtr
	)
	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")

		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	instance._free(filenamePtr)
	return encodedData
}

export async function encodeAccessDataPutFile(
	instance,
	filename,
	replaceFile,
	fileSize,
	sha256
) {
	const encoder = new TextEncoder()
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	// convert string to uint8_t ascii
	const name = encoder.encode(filename)
	//copy uint8Array to Webassembly memory
	const filenamePtr = instance._malloc(name.length + 1)
	instance.HEAPU8.set(name, filenamePtr)
	instance.HEAPU8[filenamePtr + name.length] = 0
	const sha256Ptr = instance._malloc(32)
	instance.HEAPU8.set(sha256, sha256Ptr)
	//encode msg
	let result = instance._encode_tlv_access_data_PutFile(
		outPtr,
		2000,
		actSizePtr,
		filenamePtr,
		replaceFile,
		fileSize,
		sha256Ptr
	)
	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")
		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	instance._free(filenamePtr)
	instance._free(sha256Ptr)
	return encodedData
}

export async function encodeAccessDataSendFileslice(
	instance,
	offset,
	fileSliceSize,
	data
) {
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	const dataPtr = instance._malloc(data.length)
	instance.HEAPU8.set(data, dataPtr)
	//encode msg
	let result = instance._encode_tlv_access_data_fileSliceSend(
		outPtr,
		2000,
		actSizePtr,
		offset,
		fileSliceSize,
		dataPtr
	)
	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")

		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	instance._free(dataPtr)
	return encodedData
}

export async function encodeAccessrenameGetFile(
	instance,
	oldfilename,
	newfilename
) {
	const encoder = new TextEncoder()
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	// convert string to uint8_t ascii
	let name = encoder.encode(oldfilename)
	let name2 = new Uint8Array(name.length)
	name2.set(name)
	name2[name.lenth] = 0
	//copy uint8Array to Webassembly memory
	const oldnamePtr = instance._malloc(name.length)
	instance.HEAPU8.set(name2, oldnamePtr)
	name = encoder.encode(newfilename)
	name2 = new Uint8Array(name.length)
	name2.set(name)
	name2[name.lenth] = 0
	const newNamePtr = instance._malloc(name.length)
	instance.HEAPU8.set(name2, newNamePtr)
	//encode msg
	let result = instance._encode_tlv_access_data_RenameFile(
		outPtr,
		2000,
		actSizePtr,
		oldnamePtr,
		newNamePtr
	)
	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")

		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	instance._free(oldnamePtr)
	instance._free(newNamePtr)
	return encodedData
}

export async function encodeAccessDataDeleteFile(instance, filename) {
	const encoder = new TextEncoder() //? IS
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	// convert string to uint8_t ascii
	const name = encoder.encode(filename)
	const name2 = new Uint8Array(name.length)
	name2.set(name)
	name2[name.length] = 0 //? is it too long from here on?
	//copy uint8Array to Webassembly memory

	const filenamePtr = instance._malloc(name.length)
	instance.HEAPU8.set(name2, filenamePtr)
	//encode msg

	let result = instance._encode_tlv_access_data_DeleteFile(
		outPtr,
		2000,
		actSizePtr,
		filenamePtr
	)

	//! SOLUTION: FILENAME IS TOO LONG (MAX 30)
	if (result === 0) {
		// Get the actual size of the encoded data from the WebAssembly memory
		actSize = instance.getValue(actSizePtr, "i32")
		console.log("MAX SIZE: 30 (minus foldername)")
		// Read the encoded data from the WebAssembly memory
		result = instance._encode_tlv_access_transport_payload(
			outPtr,
			2000,
			actSizePtr,
			outPtr,
			actSize
		)
	}
	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	instance._free(filenamePtr)
	return encodedData
}

export async function encodeAccessTransportGetDeviceInfo(instance) {
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output

	//encode msg
	let result = instance._encode_tlv_access_transport_get_device_info(
		outPtr,
		2000,
		actSizePtr
	)

	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	return encodedData
}

export async function encodeAccessTransportSetDeviceTime(instance, unixtime_s) {
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	//encode msg
	let result = instance._encode_tlv_access_transport_set_device_time(
		outPtr,
		2000,
		actSizePtr,
		unixtime_s
	)

	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	return encodedData
}
/*
 * This Command does not necessarily return an answer when restarted during processing Cmd
 */
export async function encodeAccessTransportResetDevice(instance, wait_ms) {
	const outPtr = instance._malloc(2000)
	const actSizePtr = instance._malloc(4)
	let actSize
	let output
	//encode msg
	let result = instance._encode_tlv_access_transport_reset_device(
		outPtr,
		2000,
		actSizePtr,
		wait_ms
	)

	actSize = instance.getValue(actSizePtr, "i32")
	output = new Uint8Array(instance.HEAPU8.buffer, outPtr, actSize)
	const encodedData = new Uint8Array(actSize)
	encodedData.set(output)
	instance._free(outPtr)
	instance._free(actSizePtr)
	return encodedData
}
