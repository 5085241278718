import axios from "axios"

export const getParameterData = async () => {
	try {
		const url =
			process.env.NODE_ENV === "production"
				? "/parameterTable"
				: "http://localhost:3000/parameterTable"

		//# Specify the sorting fields
		const sortFields = "folder_id,rank"

		//# Create the URL with query parameters for sorting
		const sortedUrl = `${url}?_sort=${sortFields}`
		const response = await axios.get(sortedUrl, {
			params: {
				fields:
					"paranr,length,defaultval,format_id,doc_id,fixedvalue_id,folder_id,rank,condition_id"
			}
		})
		return response.data
	} catch (error) {
		console.log(error)
		return error
	}
}
