import React, { useState, useEffect } from "react"
import { FolderContext } from "./ApiContext"

function FolderStore({ children }) {
	const [folderData, setFolderData] = useState(null)

	const url =
		process.env.NODE_ENV === "production"
			? "/folderTable"
			: "http://localhost:3000/folderTable"

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(url, {
					priority: "high",
					params: {
						fields: "folder_id, rank"
					}
				})
				if (!response.ok) {
					throw new Error("Network response was not ok.")
				}
				const data = await response.json()

				setFolderData(data)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchData()
	}, [url])

	return (
		<FolderContext.Provider value={[folderData, setFolderData]}>
			{children}
		</FolderContext.Provider>
	)
}
export default FolderStore
